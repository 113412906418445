<template>
	<div id="container" class="container container--fit">
		<div id="sub" class="sub mypage">
			<section class="top top--small">
				<div class="mypage__my">
					<div class="mypage__my-info">
						<div class="mypage__my-name">
							<small>
								일반회원
								<span class="mypage__my-auth">
									인증
								</span>
							</small>
							<span>
								<strong>인기많은만두</strong>
								<i class="icon icon-write"></i>
							</span>
						</div>
					</div>
					<div class="mypage__my-btn">
						<div class="mypage__my-grad">
							VIP
						</div>
						<button type="button" class="btn-modify">
							등급혜택
						</button>
					</div>
				</div>
			</section>
			<section class="content">
				<div class="box">
					<div class="mypage__wrap">
						<!-- 마이페이지 추천인 -->
						<div class="mypage__recommend">
							<div class="mypage__recommend-list">
								<div>
									추천코드
									<strong>
										GYBNEG
									</strong>
								</div>
								<span class="mypage__dot"></span>
								<div>
									총 초대회원수 1명
								</div>
							</div>
						</div>
						<!-- 마이페이지 포인트 -->
						<div class="mypage__point">
							<div class="mypage__point-item">
								<i class="icon icon-point2"></i>
								<span>포인트</span>
								<!-- [D] 내용 있을 시 grey3 클래스 제거 -->
								<strong class="mypage__point-number">
									3,000P
								</strong>
							</div>
							<div class="mypage__point-item">
								<i class="icon icon-ticket"></i>
								<span>쿠폰</span>
								<strong class="mypage__point-number">
									15장
								</strong>
							</div>
							<div class="mypage__point-item">
								<i class="icon icon-stamp"></i>
								<span>스탬프</span>
								<strong class="mypage__point-number">
									0/5
								</strong>
							</div>
						</div>
						<!-- 마이페이지 메뉴 -->
						<ul class="mypage__menu">
							<li>
								<a href="#">
									숙박예약 내역
									<span class="mypage__menu-badge">3,420</span>
								</a>
							</li>
							<li>
								<a href="#">
									레저/티켓 구매내역
									<span class="mypage__menu-badge">3,420</span>

								</a>
							</li>
							<li>
								<a href="#">
									장바구니
									<span class="mypage__menu-badge">3,420</span>

								</a>
							</li>
							<li>
								<a href="#">
									나의 리뷰
									<span class="mypage__menu-badge">
										27
									</span>
								</a>
							</li>
							<li>
								<a href="#">
									최근 본 상품
									<span class="mypage__menu-badge">
										27
									</span>
								</a>
							</li>
							<li>
								<a href="#">
									여행 취향 설정
									<small>
										여행취향 입력하고 20% 쿠폰받기
									</small>
								</a>
							</li>
							<li>
								<a href="#">
									1:1문의
									<span class="mypage__menu-badge">
										100
									</span>
								</a>
							</li>
							<li>
								<a href="#">
									환경설정
								</a>
							</li>
						</ul>
						<!-- [D] 헬로스테이 고객센터 -->
						<div class="box box--reverse">
							<div class="cs">
								<h3 class="cs__title">고객센터</h3>
								<div class="cs__item">
									<a href="tel:1234" class="btn btn-cstel">
										<i class="icon icon-tel--fill"></i>
										전화상담
									</a>
									<h4 class="cs__subtitle">고객센터 운영시간</h4>
									<p class="cs__desc">
                                        연중무휴 10시 ~ 24시 (점심) 12시30분 ~ 13시30분
									</p>
								</div>
								<div class="cs__item">
									<a href="#" class="btn btn-cskakao">
										<i class="icon icon-kakao--fill"></i>
										카카오톡 문의
									</a>
									<h4 class="cs__subtitle">카카오톡 고객센터 운영시간</h4>
									<p class="cs__desc">
                                        연중무휴 10시 ~ 19시 (점심) 12시30분 ~ 13시30분
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
	<TheNavigation></TheNavigation>
</template>

